<template>
    <section class="link-card-container">
        <Link
            class="link-card"
            :href="content.url"
        >
            <div class="link-card__image-container">
                <WatsonAtomImage
                    v-if="content.image"
                    class="link-card__image"
                    :content="content.image"
                />
                <img
                    v-else-if="page.props.brand.logo"
                    class="link-card__image link-card__image--fallback"
                    :src="page.props.brand.logo"
                >
            </div>
            <div
                v-if="content.title || content.urlText"
                class="link-card__content"
            >
                <h5
                    v-if="content.title"
                    class="link-card__content-title u-heading-5 u-regular"
                >
                    {{ content.title }}
                </h5>
                <div
                    v-if="content.urlText || urlText"
                    class="link-card__content-link u-paragraph u-bold"
                >
                    {{ content.urlText || urlText }}

                    <FontAwesomeIcon
                        :icon="faArrowRight"
                        class="link-card__content-link-icon"
                    />
                </div>
            </div>
        </Link>
    </section>
</template>

<script setup>
import WatsonAtomImage from '@atoms/image/watson-atom-image.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { usePage } from '@inertiajs/vue3';

const page = usePage();

defineProps({
    content: {
        type: Object,
        required: true,
    },
    urlText: {
        type: String,
        required: false,
        default: null,
    },
});
</script>

<style lang="scss">
@use 'watson-theme-link-card';
</style>
